(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/accountservices/quick-transaction/assets/javascripts/fullpage-transaction.js') >= 0) return;  svs.modules.push('/components/accountservices/quick-transaction/assets/javascripts/fullpage-transaction.js');
'use strict';

class FullpageTransaction {
  constructor(isWithdraw, parent) {
    this.isWithdraw = isWithdraw;
    this.parent = parent;
    this.currentValue = parent.currentValue;
    new svs.ui.Forms({
      el: 'js-quick-transaction-input'
    });
    svs.components.formValidation.init();
    this.setupTimeline();
    this.fetchElements();
    this.setEvents();
  }
  fetchElements() {
    this.closeButton = this.parent.element.getElementsByClassName('js-fullpage-close-button')[0];
    this.valueInput = this.parent.element.getElementsByClassName('js-quick-transaction-input')[0];
    this.swishButton = this.parent.element.getElementsByClassName('js-swish-button')[0];
    this.valueInputField = this.valueInput.getElementsByClassName('js-input')[0];
    if (this.isWithdraw) {
      return;
    }
    this.valueButtons = this.parent.element.getElementsByClassName('js-quick-transaction-buttons-value')[0];
  }
  setEvents() {
    this.swishButton.addEventListener('click', () => this.parent.startTransaction());
    this.closeButton.addEventListener('click', () => this.close());
    this.valueInput.addEventListener('input', () => this.updateInputValue());
    this.valueInputField.addEventListener('blur', () => this.updateValue(true));
    this.valueInputField.addEventListener('change', () => this.updateValue(true));
    if (this.isWithdraw) {
      return;
    }
    this.valueButtons.addEventListener('click', event => this.valueClick(event.target));
  }
  valueClick(valueButton) {
    const value = valueButton.innerHTML.split(' ')[0];
    this.currentValue = value;
    this.parent.setValue(this.currentValue);
    this.validateField();
  }
  updateInputValue() {
    if (this.isWithdraw) {
      this.valueInputField.setAttribute('id', 'quick-transaction-input-withdraw');
      this.valueInputField.setAttribute('data-max', this.parent.getPlayerBalance());
      this.valueInputField.setAttribute('data-min', '1');
      this.valueInputField.setAttribute('data-validate-range', "Ange ett belopp mellan 1 kr och ".concat(this.parent.getPlayerBalance(), " kr"));
    } else {
      this.valueInputField.setAttribute('id', 'quick-transaction-input-deposit');
      this.valueInputField.setAttribute('data-max', Infinity);
      this.valueInputField.setAttribute('data-min', '25');
      this.valueInputField.setAttribute('data-validate-range', "Minsta ins\xE4ttning \xE4r 25 kr");
    }
    this.validateField();
  }
  validateField() {
    const inputId = this.isWithdraw ? 'quick-transaction-input-withdraw' : 'quick-transaction-input-deposit';
    if (!svs.components.formValidation.validateFields([inputId]) || !svs.components.formValidation.validateRequiredFields([inputId])) {
      this.swishButton.disabled = true;
    } else {
      this.swishButton.disabled = false;
    }
  }
  updateValue() {
    let setParent = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;
    this.currentValue = this.valueInputField.value;
    if (setParent) {
      this.parent.setValue(this.currentValue);
    }
  }

  setValue(value) {
    this.currentValue = value;
    this.updateValue(false);
  }
  setupTimeline() {
    this.tl = gsap.timeline();
    this.tl.fromTo('.quick-transaction-box.large', {
      top: '100%'
    }, {
      top: 55,
      duration: 0.3
    });
    this.tl.fromTo('.first-animation', {
      opacity: 0
    }, {
      opacity: 1,
      duration: 0.2
    }, '>');
    this.tl.fromTo('.second-animation', {
      opacity: 0
    }, {
      opacity: 1,
      duration: 0.2
    }, '>');
    this.tl.fromTo('.fullpage-values', {
      opacity: 0
    }, {
      opacity: 1,
      duration: 0.2
    }, '>');
    this.tl.reverse();
  }
  open() {
    this.validateField();
    this.tl.restart();
    document.body.classList.add('transaction-no-scroll');
  }
  close() {
    if (this.currentValue !== '') {
      if (this.isWithdraw && this.currentValue > this.parent.getPlayerBalance()) {
        this.parent.setValue(this.parent.getPlayerBalance());
      } else if (!this.isWithdraw && this.currentValue < this.parent.minValue) {
        this.parent.setValue(this.parent.minValue);
      }
    }
    this.tl.reverse();
    document.body.classList.remove('transaction-no-scroll');
  }
}
svs.accountservices.quick_transaction.FullpageTransaction = FullpageTransaction;

 })(window);