(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/accountservices/quick-transaction/views/fullpage-transaction.js') >= 0) return;  svs.modules.push('/components/accountservices/quick-transaction/views/fullpage-transaction.js');
(function(svs, Handlebars) {
_svs=window.svs=window.svs||{};
_svs.accountservices=_svs.accountservices||{};
_svs.accountservices.quick_transaction=_svs.accountservices.quick_transaction||{};
_svs.accountservices.quick_transaction.templates=_svs.accountservices.quick_transaction.templates||{};
svs.accountservices.quick_transaction.templates.fullpage_transaction = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    return "Sätt in";
},"3":function(container,depth0,helpers,partials,data) {
    return "Ta ut";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = (lookupProperty(helpers,"ui")||(depth0 && lookupProperty(depth0,"ui"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"form/control_group",{"name":"ui","hash":{"formv2":true,"errorMessage":"Ange Belopp i hela kronor"},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":12},"end":{"line":21,"column":19}}})) != null ? stack1 : "");
},"6":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                "
    + container.escapeExpression((lookupProperty(helpers,"ui")||(depth0 && lookupProperty(depth0,"ui"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"form/input_field",{"name":"ui","hash":{"data-required":"Ange ett belopp","data-validate-only-digits":"Felaktigt belopp, ange belopp i hela kronor","inputHelpText":"Ange belopp i hela kronor","type":"number","suffixAbbr":"kronor","suffix":"kr","label":"Belopp","autocomplete":"off","required":true,"class":"input-valid"},"data":data,"loc":{"start":{"line":9,"column":16},"end":{"line":20,"column":18}}}))
    + "\n";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"quick-transaction-fullpage-buttons js-quick-transaction-buttons-value fullpage-values hidden\">\n                "
    + ((stack1 = (lookupProperty(helpers,"ui")||(depth0 && lookupProperty(depth0,"ui"))||alias2).call(alias1,"button",{"name":"ui","hash":{"inverted":true,"transparent":true,"block":true,"class":"margin-xs-top-2","size":300},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":25,"column":16},"end":{"line":25,"column":120}}})) != null ? stack1 : "")
    + "\n                "
    + ((stack1 = (lookupProperty(helpers,"ui")||(depth0 && lookupProperty(depth0,"ui"))||alias2).call(alias1,"button",{"name":"ui","hash":{"inverted":true,"transparent":true,"block":true,"class":"margin-xs-left-1 margin-xs-top-2","size":300},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":26,"column":16},"end":{"line":26,"column":137}}})) != null ? stack1 : "")
    + "\n                "
    + ((stack1 = (lookupProperty(helpers,"ui")||(depth0 && lookupProperty(depth0,"ui"))||alias2).call(alias1,"button",{"name":"ui","hash":{"inverted":true,"transparent":true,"block":true,"class":"margin-xs-left-1 margin-xs-top-2","size":300},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":27,"column":16},"end":{"line":27,"column":137}}})) != null ? stack1 : "")
    + "\n            </div>\n";
},"9":function(container,depth0,helpers,partials,data) {
    return "100 kr";
},"11":function(container,depth0,helpers,partials,data) {
    return "250 kr";
},"13":function(container,depth0,helpers,partials,data) {
    return "500 kr";
},"15":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"deposit") : depth0),{"name":"if","hash":{},"fn":container.program(16, data, 0),"inverse":container.program(18, data, 0),"data":data,"loc":{"start":{"line":32,"column":118},"end":{"line":32,"column":170}}})) != null ? stack1 : "")
    + container.escapeExpression((lookupProperty(helpers,"ui")||(depth0 && lookupProperty(depth0,"ui"))||container.hooks.helperMissing).call(alias1,"bank",{"name":"ui","hash":{"name":"swish","size":24},"data":data,"loc":{"start":{"line":32,"column":170},"end":{"line":32,"column":204}}}));
},"16":function(container,depth0,helpers,partials,data) {
    return "Sätt in via ";
},"18":function(container,depth0,helpers,partials,data) {
    return "Ta ut via ";
},"20":function(container,depth0,helpers,partials,data) {
    return "Stäng";
},"22":function(container,depth0,helpers,partials,data) {
    return "Byt betalmetod";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"quick-transaction-fullpage\">\n    <div class=\"quick-transaction-fullpage-flex\">\n        <h1 class=\"align-center f-900 fullpage-header hidden first-animation\">"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"deposit") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":3,"column":78},"end":{"line":3,"column":120}}})) != null ? stack1 : "")
    + "</h1>\n"
    + ((stack1 = (lookupProperty(helpers,"ui")||(depth0 && lookupProperty(depth0,"ui"))||alias2).call(alias1,"form",{"name":"ui","hash":{"autocomplete":"off","class":"js-quick-transaction-input hidden second-animation","vertical":true},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":8},"end":{"line":22,"column":15}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"deposit") : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":23,"column":8},"end":{"line":29,"column":15}}})) != null ? stack1 : "")
    + "    </div>\n    <div class=\"quick-transaction-fullpage-flex quick-transaction-fullpage-bottom\">\n        "
    + ((stack1 = (lookupProperty(helpers,"ui")||(depth0 && lookupProperty(depth0,"ui"))||alias2).call(alias1,"button",{"name":"ui","hash":{"inverted":true,"block":true,"size":300,"class":"swish-button js-swish-button hidden first-animation"},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":32,"column":8},"end":{"line":32,"column":211}}})) != null ? stack1 : "")
    + "\n        <div class=\"quick-transaction-fullpage-buttons fullpage-buttons hidden second-animation\">\n            "
    + ((stack1 = (lookupProperty(helpers,"ui")||(depth0 && lookupProperty(depth0,"ui"))||alias2).call(alias1,"button",{"name":"ui","hash":{"inverted":true,"transparent":true,"block":true,"class":"margin-xs-top-1 js-fullpage-close-button","size":300},"fn":container.program(20, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":34,"column":12},"end":{"line":34,"column":140}}})) != null ? stack1 : "")
    + "\n            "
    + ((stack1 = (lookupProperty(helpers,"ui")||(depth0 && lookupProperty(depth0,"ui"))||alias2).call(alias1,"button",{"name":"ui","hash":{"href":(lookupProperty(helpers,"urlMapping")||(depth0 && lookupProperty(depth0,"urlMapping"))||alias2).call(alias1,"deposit",{"name":"urlMapping","hash":{},"data":data,"loc":{"start":{"line":35,"column":124},"end":{"line":35,"column":146}}}),"inverted":true,"transparent":true,"block":true,"class":"margin-xs-left-1 margin-xs-top-1","size":300},"fn":container.program(22, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":35,"column":12},"end":{"line":35,"column":170}}})) != null ? stack1 : "")
    + "\n        </div>\n    </div>\n</div>\n";
},"useData":true});
Handlebars.partials['components-accountservices-quick_transaction-fullpage_transaction'] = svs.accountservices.quick_transaction.templates.fullpage_transaction;
})(svs, Handlebars);


 })(window);