(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/accountservices/quick-transaction/assets/javascripts/quick-transaction.js') >= 0) return;  svs.modules.push('/components/accountservices/quick-transaction/assets/javascripts/quick-transaction.js');


'use strict';

class QuickTransaction {
  constructor() {
    var _svs$components$Stora;
    let isWithdraw = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
    let element = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : undefined;
    let shouldAnimate = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;
    let returnUrl = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : null;
    this.element = element || document.body.getElementsByClassName("js-quick-transaction-".concat(this.isWithdraw ? 'withdraw' : 'deposit'))[0];
    this.smallTransactionBox = this.element.querySelector('.small');
    this.mainContent = document.getElementsByClassName('pnp-main-content')[0];
    this.logger = svs.core.log.getLogger('accountservices:quick-transaction');
    this.init = true;
    this.returnUrl = returnUrl;
    this.lowerSteppingNumber = 0;
    this.upperSteppingNumber = 0;
    this.isWithdraw = isWithdraw;
    this.maxValue = this.isWithdraw ? this.getPlayerBalance() : Infinity;
    this.minValue = 25;
    this.currentValue = ((_svs$components$Stora = svs.components.Storage) === null || _svs$components$Stora === void 0 || (_svs$components$Stora = _svs$components$Stora.browser) === null || _svs$components$Stora === void 0 || (_svs$components$Stora = _svs$components$Stora.get('depositValues', 'quick-deposit')) === null || _svs$components$Stora === void 0 ? void 0 : _svs$components$Stora.amount) || 100;
    this.fullPage = new svs.accountservices.quick_transaction.FullpageTransaction(this.isWithdraw, this);
    if (!shouldAnimate) {
      this.element.classList.add('quick-transaction-show');
      this.smallTransactionBox.classList.add('quick-transaction-small-show');
    }
    this.element.classList.remove('quick-transaction-hide');
    this.calcSteppingNumber();
    this.fetchElements();
    this.updateValue();
    this.addEvents();
  }
  fetchElements() {
    this.transactionButton = this.element.getElementsByClassName("js-transaction-main-button")[0];
    this.balanceButton = this.element.getElementsByClassName("js-quick-button-sum")[0];
    this.plusButton = this.element.getElementsByClassName("js-transaction-button-up")[0];
    this.minusButton = this.element.getElementsByClassName("js-transaction-button-down")[0];
    this.valueLabel = this.element.getElementsByClassName("js-quick-transaction-value")[0];
  }
  addEvents() {
    var _this$transactionButt, _this$balanceButton, _this$plusButton, _this$minusButton;
    (_this$transactionButt = this.transactionButton) === null || _this$transactionButt === void 0 || _this$transactionButt.addEventListener('click', () => this.startTransaction());
    (_this$balanceButton = this.balanceButton) === null || _this$balanceButton === void 0 || _this$balanceButton.addEventListener('click', () => this.fullPage.open());
    (_this$plusButton = this.plusButton) === null || _this$plusButton === void 0 || _this$plusButton.addEventListener('click', () => this.add(this.upperSteppingNumber));
    (_this$minusButton = this.minusButton) === null || _this$minusButton === void 0 || _this$minusButton.addEventListener('click', () => this.subtract(this.lowerSteppingNumber));
  }

  setValue(value) {
    this.currentValue = value;
    this.updateValue(false);
  }
  startTransaction() {
    if (svs.core.detect.feature('ft-slim-login')) {
      svs.ui.loader.show();
      svs.core.module.load('/cl/accountservices-react/react:login-slim', null, () => {
        svs.accountservices_react.login_slim.init();
        svs.ui.loader.hide();
      }, false);
    } else if (this.isWithdraw) {
      this.startWithdraw();
    } else {
      this.startDeposit();
    }
  }
  startWithdraw() {
    svs.utils.url.navigate(svs.core.urlMapping.get('withdrawal'));
  }
  async startDeposit() {
    const depositUrl = svs.core.urlMapping.get('quickDeposit');
    const isLoggedOn = svs.core.userSession.hasRole(svs.core.userSession.roles.PLAYER);
    const skipStepOneParam = 'skipStepOne=true';
    const depositValues = {
      amount: this.currentValue,
      skipStepOne: isLoggedOn 
    };
    const storageTimeInMinutes = 30;
    if (!isLoggedOn) {
      svs.components.analytics.trackEvent({
        action: 'initiate_onboarding',
        opt_label: depositValues.amount.toString(),
        category: 'Casino'
      });
    }
    try {
      const hasRestriction = await svs.accountservices.player_exclusions.checkHasRestrictionAndShowDialog();
      if (!hasRestriction) {
        svs.components.Storage.browser.set('depositValues', 'quick-deposit', depositValues, storageTimeInMinutes, error => {
          if (error) {
            this.logger.warn("Failed to set depositValues: ".concat(error));
          }
          let finalUrl = depositUrl;
          if (isLoggedOn) {
            finalUrl += "?".concat(skipStepOneParam);
          }
          if (this.returnUrl) {
            finalUrl += "".concat(isLoggedOn ? '&' : '?', "returnUrl=").concat(this.returnUrl);
          }
          svs.utils.url.navigate(finalUrl);
        }, this);
      }
    } catch (e) {
      this.logger.error('Error checking player restrictions', e);
    }
  }
  getNewNumber(number) {
    let add = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
    const oldNumber = this.currentValue;
    const roundedNumber = this.roundToNearestNumber(oldNumber, number);
    let newNumber = 0;
    if (add) {
      newNumber = roundedNumber + number;
      if (newNumber > this.maxValue) {
        return this.maxValue;
      }
      return newNumber;
    }
    newNumber = roundedNumber === oldNumber ? oldNumber - number : roundedNumber;
    if (newNumber < this.minValue) {
      return this.minValue;
    }
    return newNumber;
  }
  add(numberToAdd) {
    this.currentValue = this.getNewNumber(numberToAdd);
    this.updateValue();
  }
  subtract(numberToSub) {
    this.currentValue = this.getNewNumber(numberToSub, false);
    this.updateValue();
  }
  getPlayerBalance() {
    var _svs$accountservices;
    const balance = ((_svs$accountservices = svs.accountservices) === null || _svs$accountservices === void 0 || (_svs$accountservices = _svs$accountservices.quick_transaction) === null || _svs$accountservices === void 0 || (_svs$accountservices = _svs$accountservices.data) === null || _svs$accountservices === void 0 ? void 0 : _svs$accountservices.balance) || 1000;
    switch (typeof balance) {
      case 'string':
        return parseFloat(balance.replace(',', '.'));
      case 'number':
        return balance;
      default:
        this.logger.error("Unexpected balance type in getPlayerBalance. Type: ".concat(typeof balance, " value: ").concat(balance));
        return undefined;
    }
  }
  updateValue() {
    var _this$plusButton2, _this$minusButton2;
    let setChild = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;
    if (setChild) {
      this.fullPage.setValue(this.currentValue);
    }
    if (this.valueLabel) {
      this.valueLabel.innerHTML = "".concat(String(this.currentValue).replace('.', ','), " kr");
    }
    (_this$plusButton2 = this.plusButton) === null || _this$plusButton2 === void 0 || _this$plusButton2.classList.remove('qt-btn-disabled');
    (_this$minusButton2 = this.minusButton) === null || _this$minusButton2 === void 0 || _this$minusButton2.classList.remove('qt-btn-disabled');
    if (this.minusButton && this.plusButton) {
      this.plusButton.disabled = false;
      this.minusButton.disabled = false;
      if (this.currentValue <= this.minValue) {
        this.minusButton.classList.add('qt-btn-disabled');
        this.minusButton.disabled = true;
      }
      if (this.currentValue >= this.maxValue) {
        this.plusButton.classList.add('qt-btn-disabled');
        this.plusButton.disabled = true;
      }
    }
    this.fullPage.valueInputField.value = this.currentValue;
    this.calcSteppingNumber();
  }
  calcSteppingNumber() {
    switch (true) {
      case this.currentValue < 100:
        this.setNumbers(10, 10);
        break;
      case this.currentValue === 100:
        this.setNumbers(10, 50);
        break;
      case this.currentValue < 1000:
        this.setNumbers(50, 50);
        break;
      case this.currentValue === 1000:
        this.setNumbers(50, 100);
        break;
      default:
        this.setNumbers(100, 100);
        break;
    }
  }
  setAnimation() {
    this.drawer.show();
    ScrollTrigger.create({
      onUpdate: self => {
        if (self.direction === -1) {
          this.drawer.show();
        } else {
          this.drawer.hide();
        }
      }
    });
  }
  setNumbers(lower, upper) {
    this.lowerSteppingNumber = lower;
    this.upperSteppingNumber = upper;
  }
  roundToNearestNumber(oldValue, number) {
    return Math.floor(oldValue / number) * number;
  }
}
svs.accountservices.quick_transaction.QuickTransaction = QuickTransaction;

 })(window);