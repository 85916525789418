(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/accountservices/quick-transaction/assets/javascripts/transaction-buttons.js') >= 0) return;  svs.modules.push('/components/accountservices/quick-transaction/assets/javascripts/transaction-buttons.js');

'use strict';

class TransactionButtons {
  constructor() {
    this.fetchElements();
    this.setupClasses();
    this.setupEvents();
    this.addAnimations();
  }
  addAnimations() {
    const showButtons = gsap.from(this.element, {
      yPercent: 150,
      duration: 0.4
    }).progress(1);

    this.tl = new gsap.timeline({
      scrollTrigger: {
        start: '+=120',
        trigger: document.querySelector('.pnp-background'),
        scrub: true,
        onUpdate: self => {
          if (self.direction === -1) {
            self.getVelocity() < -100 && showButtons.play();
          } else {
            showButtons.reverse();
          }
        }
      }
    });
  }
  fetchElements() {
    this.element = document.body.getElementsByClassName('js-quick-transaction-buttons')[0];
    this.withdrawButton = this.element.getElementsByClassName('js-quick-transaction-button-withdraw')[0];
    this.depositButton = this.element.getElementsByClassName('js-quick-transaction-button-deposit')[0];
    this.withdrawElement = document.body.getElementsByClassName('js-quick-transaction-withdraw')[0];
    this.depositElement = document.body.getElementsByClassName('js-quick-transaction-deposit')[0];
    this.smallTransactionBoxWithdraw = this.withdrawElement.querySelector('.small');
    this.smallTransactionBoxDeposit = this.depositElement.querySelector('.small');
    this.mainContent = document.getElementsByClassName('js-quick-transaction')[0];
    this.transactionBox = document.getElementsByClassName('js-quick-transaction-box small')[0];
  }
  setupEvents() {
    this.withdrawButton.addEventListener('click', () => {
      svs.utils.url.navigate(svs.core.urlMapping.get('withdrawal'));
      this.trackTransaction('withdrawal_icon');
    });
    this.depositButton.addEventListener('click', () => {
      this.depositElement.classList.add('quick-transaction-show');
      this.smallTransactionBoxDeposit.classList.add('quick-transaction-small-show');
      this.depositElement.classList.add('show-overlay');
      this.trackTransaction('deposit_icon');
    });
    this.mainContent.addEventListener('click', event => {
      if (event.target.contains(this.transactionBox) && event.target !== this.transactionBox) {
        this.depositElement.classList.remove('quick-transaction-show');
        this.smallTransactionBoxDeposit.classList.remove('quick-transaction-small-show');
        this.mainContent.classList.remove('show-overlay');
      }
    });
  }
  setupClasses() {
    this.withdrawClass = new svs.accountservices.quick_transaction.QuickTransaction(true, this.withdrawElement);
    this.depositClass = new svs.accountservices.quick_transaction.QuickTransaction(false, this.depositElement);
    this.withdrawElement.classList.remove('quick-transaction-hide');
    this.depositElement.classList.remove('quick-transaction-hide');
    this.element.classList.remove('quick-transaction-hide');
  }
  trackTransaction(transactionType) {
    svs.components.analytics.trackEvent({
      category: 'Casino',
      action: transactionType,
      opt_label: document.location.pathname
    });
  }
}
svs.accountservices.quick_transaction.TransactionButtons = TransactionButtons;

 })(window);