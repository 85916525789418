(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/accountservices/quick-transaction/assets/javascripts/helpers/handlebarshelpers.js') >= 0) return;  svs.modules.push('/components/accountservices/quick-transaction/assets/javascripts/helpers/handlebarshelpers.js');

'use strict';

let trinidad;
if (svs.isServer) {
  const hbs = require('hbs');
  trinidad = require('trinidad-core').core;
  registerHelpers(hbs);
} else {
  registerHelpers(Handlebars);
}
function registerHelpers(hbs) {
  hbs.registerHelper('swishIsDisabled', function (options) {
    if (svs.isServer) {
      var _trinidad$options$cms;
      if ((_trinidad$options$cms = trinidad.options.cms) !== null && _trinidad$options$cms !== void 0 && (_trinidad$options$cms = _trinidad$options$cms.disabledPaymentMethods) !== null && _trinidad$options$cms !== void 0 && _trinidad$options$cms.SWISH) {
        return options.fn(this);
      }
    } else {
      var _svs$accountservices;
      if ((_svs$accountservices = svs.accountservices) !== null && _svs$accountservices !== void 0 && (_svs$accountservices = _svs$accountservices.quick_transaction) !== null && _svs$accountservices !== void 0 && (_svs$accountservices = _svs$accountservices.data) !== null && _svs$accountservices !== void 0 && _svs$accountservices.swishIsDisabled) {
        return options.fn(this);
      }
    }
    return options.inverse(this);
  });
}

 })(window);